@charset "UTF-8";

/*
***************************************************
    Common SCSS
***************************************************
    2008/06/26	初版
    2009/06/05	改訂
    2010/03/30	改訂（指定フォント変更）
    2010/04/01	ページTOPへのスタイル訂正
    2010/04/16	ディテール等訂正
    2010/10/07	改訂
    2018/06/22	改訂
    2021/01/13  CSS->SCSS
***************************************************
*/

html, body {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    /*color: #4A4D5D;
    background: #F3F3FA url(/img/bg.gif) repeat-y;*/
    color: #797c80;
    background-color: #F8F8F8;
    font-family: Verdana, Myriad, Helvetica, "ＭＳ Ｐゴシック", Osaka, "ヒラギノ角ゴ Pro W3", sans-serif;
}

a img {
    margin: 0;
    padding: 0;
    border: none;
}

a {
    outline: none;
}

p {
    margin: 0 0 1em 0;
    padding: 0;
}

div, form,
h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}


/* フォント関連 */
.xsmall {
    font-size: 10px;
}

.small {
    font-size: 12px;
}

.medium {
    font-size: 14px;
}

.large {
    font-size: 16px;
}

.xlarge {
    font-size: 18px;
}

.sbody {
    line-height: 125%;
}

.mbody {
    line-height: 150%;
}

.lbody {
    line-height: 175%;
}

.xlbody {
    line-height: 200%;
}

.bold {
    font-weight: bold;
}


/* フォント配色関連 */
.white {
    color: #fff;
}

.gray {
    color: #999;
}

.red {
    color: #f00;
}

.orange {
    color: #fe9b31;
}

.light_gray {
    color: #797c80;
}


h1, h2, h3, h4, h5, h6 {
    /*color: #535353;*/
    color: #5b5c5e;
}

.copyright {
    color: #757B8F;
}

.sep {
    color: #D7DAE7;
    font-weight: normal;
    margin: 0 3px;
}

.error {
    color: #fe9b31;
    /* font-weight: bold; */
}


/* リンク関連 */
a {
    /*color: #438FCE;*/
    color: #82B5D4;
    text-decoration: none;
}

a:visited {
    color: #82B5D4;
    text-decoration: none;
}

a:hover {
    color: #69A1C7;
    text-decoration: underline;
}

a:active {
    color: #69A1C7;
    text-decoration: underline;
}


/* Blog等タイトル */
a.title {
    /*color: #2F47FF;*/
    color: #464646;
    text-decoration: none;
}

a.title:visited {
    color: #464646;
    text-decoration: none;
}

a.title:hover {
    color: #464646;
    text-decoration: underline;
}

a.title:active {
    color: #464646;
    text-decoration: underline;
}


/* マージン */
.margin1 {
    margin-bottom: 1px;
}

.margin3 {
    margin-bottom: 3px;
}

.margin5 {
    margin-bottom: 5px;
}

.margin10 {
    margin-bottom: 10px;
}

.margin15 {
    margin-bottom: 15px;
}

.margin20 {
    margin-bottom: 20px;
}

.margin25 {
    margin-bottom: 25px;
}

.margin30 {
    margin-bottom: 30px;
}


/*
***************************************************
*/

/* ヘッダ
---------------------------------------------------
*/

//div#header {
//    width: 950px;
//    height: 40px;
//    border-bottom: 1px solid #F2F2F2;
//    padding: 19px 0 0 0;
//    margin-bottom: 15px;
//}
//
//div#header div#left_block {
//    float: left;
//    width: 650px;
//}
//
//div#header div#right_block {
//    float: right;
//    width: 185px;
//    text-align: right;
//    margin: 0 15px 0 0;
//    padding: 0;
//}
//
//h1#logo {
//    float: left;
//    margin: 0 50px 0 15px;
//    padding: 0;
//}
//
//div#header ul {
//    margin: 6px 0 0 0;
//    padding: 0;
//    list-style: none;
//}
//
//div#header ul#menu_main li {
//    display: inline;
//    margin: 0 20px 0 0;
//    padding: 0;
//}
//
//div#header ul#menu_lang li {
//    display: inline;
//    margin: 0;
//    padding: 0;
//}


/* メインコンテンツ
---------------------------------------------------
*/

div#container {
    /*clear: both;*/
    width: 950px;
    min-width: 950px;
    height: auto;
    min-height: 100%;
    margin: 0 auto;
    background: #FFFFFF;
    /*border-left: 1px solid #EDEDED;
    border-right: 1px solid #EDEDED;*/
}

div#container div#block_menu {
    float: left;
    width: 210px;
}

div#container div#block_contents {
    float: right;
    width: 740px;
}

div#block_menu h2#title_main {
    margin: 0 15px 10px 15px;
}

/* サブメニュー */
div#block_menu ul.contents_menu_sub {
    display: block;
    margin: 0 10px 12px 15px;
    padding: 0;
    list-style-type: none;
    /* IE7対策（liに妙な隙間が空くための対処） */
    line-height: 16px;
}

ul.contents_menu_sub li.level_0 {
    margin: 0;
    padding: 0 0 3px 1px;

    /*
    padding: 0 0 3px 9px;
    background: url(/img/img_arrow_detail.gif) no-repeat 2px 6px;
    */
}

ul.contents_menu_sub li.level_1 {
    margin: 0;
    padding: 0 0 3px 15px;
    background: url(/img/img_arrow_detail2.gif) no-repeat 7px 6px;

    /*
    padding: 0 0 3px 19px;
    background: url(/img/img_arrow_detail2.gif) no-repeat 12px 6px;
    */
}

/* コンテンツサブタイトル */
h3.title_sub {
    display: block;
    margin: 0 0 15px 15px;
    padding: 0;
    font-weight: normal;
    color: #4b5470;
}

/* 一覧に戻る */
p.to_list {
    margin: 0 15px 15px 15px;
}


/* ページナビ */
div.page_navi {
    margin: 0 15px;
    padding: 0;
}

div.page_navi div.block_left {
    float: left;
    width: 20%;
    text-align: left;
}

div.page_navi div.block_center {
    float: left;
    width: 60%;
    text-align: center;
}

div.page_navi div.block_right {
    float: left;
    width: 20%;
    text-align: right;
}


/* 点線 */
div.hr {
    height: 1px;
    background: url(/img/bg_line_dot.gif) repeat-x;
    overflow: hidden;
    font-size: 1px;
}

div.hr_contents {
    height: 1px;
    margin: 0 5px 15px 5px;
    padding: 0;
    background: url(/img/bg_line_dot.gif) repeat-x;
    overflow: hidden;
    font-size: 1px;
}


/* float解除用 */
div.clear {
    clear: both;
}

.clearfix:after {
    content: "."; /* 新しい要素を作る */
    display: block; /* ブロックレベル要素に */
    clear: both;
    height: 0;
    visibility: hidden;
}

.clearfix {
    min-height: 1px;
}

* html .clearfix {
    height: 1px;
    /*\*//*/
    height: auto;
    overflow: hidden;
    /**/
}

/* page top */
div.page_top {
    clear: both;
    margin: 15px;
    text-align: right;
}

div.page_top a {
    width: 90px;
    overflow: hidden;
    padding-right: 10px;
    background: url(/img/img_arrow_up.gif) no-repeat right 4px;
}

/* Footer */
div#footer {
    clear: both;
    width: 950px;
    /*border-top: 1px solid #EDEDED;*/
    border-top: 1px solid #F2F2F2;
    background-color: #fff;
}

div#footer p#copyright {
    margin: 0;
    padding: 12px 15px 30px 15px;
    color: #9a9ead;
}

/*
div#footer div#copyright {
	float: left;
	width: 500px;
	margin: 0;
	padding: 8px 15px 25px 15px;
	color: #9a9ead;
}

div#footer div#submenu {
	float: right;
	width: 250px;
	margin: 0;
	padding: 8px 15px 25px 0;
	text-align: right;
}
*/

a.footer {
    color: #9a9ead;
    text-decoration: none;
}

a.footer:visited {
    color: #9a9ead;
    text-decoration: none;
}

a.footer:hover {
    color: #2a324d;
    text-decoration: underline;
}

a.footer:active {
    color: #2a324d;
    text-decoration: underline;
}


/* フォーム関係 */
input.cm_input {
    width: 250px;
}

textarea.cm_comment {
    width: 345px;
}

input.button {
    width: 120px;
    cursor: pointer;
}

input.sbutton {
    width: 70px;
    cursor: pointer;
}

input.short {
    width: 100px;
}

input.mid {
    width: 250px;
}

input.long {
    width: 500px;
}

textarea.long {
    width: 500px;
}


/* ナビゲーション */
p.nav_prev {
    margin: 0;
    padding-left: 12px;
    background: url(/img/img_arrow_prev.gif) no-repeat left 4px;
}

p.nav_next {
    margin: 0;
    padding-right: 12px;
    background: url(/img/img_arrow_next.gif) no-repeat right 4px;
}