@charset "UTF-8";

/*
----------------------------------------------
    Header用SCSS
----------------------------------------------
    2021/01/13
----------------------------------------------
*/

#header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;

    border-bottom: 1px solid #F2F2F2;
    margin: 0 0 15px 0;
    height: 59px;
    width: 100%;

    img {
        vertical-align: bottom;
    }

    ul {
        font-size: 0;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;

            & + li {
                margin-left: 20px;
            }

            a {
                display: block;
                margin-top: 3px;
            }
        }
    }

    .left-block {
        display: flex;
        align-items: center;
        width: 650px;

        h1.logo {
            font-size: 11px;
            line-height: 1;
            margin-top: -3px;
            width: 225px;

            a {
                display: block;
                margin-left: 15px;
            }
        }
    }

    .right-block {
        display: flex;
        justify-content: flex-end;

        ul {
            margin-right: 15px;
        }
    }
}

// menu
div#container {
    div#block_menu {
        h2#title_main {
            font-size: 0;
            line-height: 1;

            a {
                display: inline-block;
            }
        }
    }
}


