@charset "UTF-8";

/*
----------------------------------------------
    Contact用 追加SCSS
----------------------------------------------
    2021/01/13
----------------------------------------------
*/

.block-contents.contact {
    table {
        border: 0;
        border-collapse: collapse;
        margin: 0;
        padding: 0;

        tr {
            td {
                padding: 0;

                &:first-child {
                    font-size: 0;
                    line-height: 1;
                }
            }
        }
    }

    &.input {
        table {
            tr:nth-child(5) {
                td:first-child,
                td:nth-child(2) {
                    padding-top: 3px;
                }

                td {
                    span.error {
                        display: block;
                        margin-top: 3px;
                    }

                    textarea {
                        display: block;
                    }

                    span.error + textarea {
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    &.confirm {
        table {
            tr {
                td:first-child,
                td:nth-child(2) {
                    font-size: 0;
                    line-height: 1;
                }
            }
        }
    }
}